<template>
  <div class="container my-24 my-lg-40">
    <div class="col">
      <processing-check />
    </div>
  </div>
</template>

<script>
import ProcessingCheck from '@views/Processing/ProcessingCheck'

export default {
  name: 'SCheck',

  components: {
    ProcessingCheck
  },

  data () {
    return {
      service: {
        event: null
      }
    }
  },

  provide () {
    return {
      sendData: this.sendData,
      service: this.service
    }
  }
}
</script>
